@keyframes pulse-primary {
    0% {
        box-shadow: 0 0 0 0 rgba(191, 4, 91, 0.7);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(191, 4, 91, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(191, 4, 91, 0);
    }
}

.pulses-primary {
    animation: pulse-primary 2s infinite;
}

@keyframes pulse-tertiary {
    0% {
        box-shadow: 0 0 0 0 rgba(31, 151, 172, 0.7);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(191, 4, 91, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(191, 4, 91, 0);
    }
}

.pulses-tertiary {
    animation: pulse-tertiary 2s infinite;
}

.wavy {
    animation-name: wavy;
    animation-duration: 2.5s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    position: relative;
    color: #79c1cd;
    top: 0;
    left: 0;
}
@keyframes wavy {
    0% {
        top: 0px;
        color:#79c1cd;
    }
    25% {
        top: -2px;
        color: #166a78;
    }
    50% {
        top: -2px;
        color: #bf045b;
    }
    100% {
        top: 0px;
        color:#79c1cd;
    }
}